<template>
  <div>
    <Head :title="$t('share.title')"></Head>
    <!-- 背景 -->
    <img src="@/image/bg_03.jpg" class="bg" alt="" :style="'width:'+$setWidth">
    <!-- logo -->
    <div class="y_center logo">
      <img src="@/image/logo.png">
      <div><span style="color: #21c7d5;">M</span><span style="color: #21c7d5;">T</span><span style="color: #21c7d5;">DAO</span></div>
    </div>
    <!-- 主体 -->
    <div class="share center" :style="'width:'+$setWidth">
      <div class="share_box y_center">
        <div style="margin:15px 0;font-size:22px;"><span style="color: #21c7d5;">M</span><span style="color: #21c7d5;">T</span><span style="color: #21c7d5;">DAO</span></div>
        <div style=" font-size: 14px;">{{$t('share.text1')}}</div>
        <!-- 二维码 -->
        <div class="flex_center share_ewm">
          <div class="qrcode" ref="qrCodeUrl"></div>
          <!-- <img src="@/image/ewm.png" alt=""> -->
        </div>
        <div style="font-size: 16px;margin-bottom: 10px;">{{$t('share.text2')}}</div>
        <div class="share_url" >{{url}}</div>
        <button class="share_btn" @click="copy(url)">{{$t('share.text3')}}</button>
        <!-- <div style="font-size: 16px;margin-bottom: 10px;">{{$t('share.text4')}}</div>
        <div class="flex_row">
          <div class="share_url">{{address}}</div>
          <i class="ifont icone-fuzhi-11" @click="copy(address)" style="color: #21C7D5;margin-left: 5px;"></i>
        </div> -->
      </div>
    </div>
  </div>
</template>
 
<script>
import QRCode from 'qrcodejs2'
export default {
  data () {
    return {
      url:'https://mtdaofreedom.net/#/?id='+this.$route.params.wallet,
      address:'',
    }
  },
  computed: {
    
  },
  mounted () {
    var base_url = window.location.protocol + '//' + window.location.host
    this.url=base_url+'/#/?id='+this.$route.params.wallet
    this.creatQrCode()
    this.init()
    this.address = this.$route.params.wallet
  },
  methods: {
    init() {
      
    },
    creatQrCode() {
      var text=''
      var text=this.url
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: text, // 需要转换为二维码的内容
            width: 130,
            height: 130,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },
    copy(text){
      this.$copyText(text).then(e=>{
        this.$toast('复制成功')
      }, e=> {
        this.$toast('复制失败')
      })
    },
  }
}
</script>
 
<style lang="scss" scoped>
  .bg{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
  }
  .logo{
    font-size: 20px;
    img{
      width: 80px;
      margin: 20px 0 10px;
    }
  }
  .share{
    padding: 0 20px;
    margin-top: 20px;
    .share_box{
      background-color: #fff;
      padding: 0 15px 20px;
      border-radius: 5px;
      .share_ewm{
        margin: 10px 0;
        border: 1px solid #21C7D5;
        border-radius: 10px;
        padding: 10px;
        width: 150px;
        height: 150px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .share_url{
        word-break: break-all;
      }
      .share_btn{
        background-color: #21C7D5;
        border: 0;
        color: #ffffff;
        width: 100%;
        height:40px;
        border-radius: 5px;
        font-size: 16px;
        margin: 10px 0;
      }
    }
  }
</style>